import React from "react"

export interface Props {
  width?: "three"
}

export const Columns: React.FC<Props> = ({ children, width}) => (
  <div className={`columns ${width || ''}`}>
    {children}
  </div>
)

export const Column: React.FC = ({ children }) => (
  <div className="column">
    {children}
  </div>
)
