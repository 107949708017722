import React from "react"
import { Container } from "./Container"


export interface Props {
  id: string;
  className?: string;
}

export const Section: React.FC<Props> = ({ children, id, className }) => (
  <section id={id} className={className}>
    <Container>
      {children}
    </Container>
  </section>
)
