import React, { ReactElement, useEffect, useRef } from "react"
import { Container } from "./Container"
import { Column, Columns } from "./Columns"
import { Props as SEOProps, SEO } from "./SEO"
import { Link } from "gatsby"
import logo from "../images/logo.png";


export interface Props {
  header?: ReactElement;
  seo: SEOProps;
}

export const Layout: React.FC<Props> = ({ children, header, seo }) => {
  const nav = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!nav.current) {
      return
    }
    if(typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle("stuck", e.intersectionRatio < 1),
        {
          rootMargin: "-1px 0px 0px 0px",
          threshold: [1]
        }
      )
      observer.observe(nav.current)

      return () => observer.disconnect()
    }
  }, [nav])
  return (
    <div className="layout">
      <SEO {...seo}/>
      {header}
      <div className="page">
        <nav ref={nav}>
          <Container>
            <a className="name" href="/">
              <img src={logo} alt="Tocabi Software"/>
            </a>
            <div className="links">
              <a href="https://github.com/ChappIO" target="_blank"><i className="fab fa-github"/></a>
              <a href="https://www.linkedin.com/in/thomasbiesaart/" target="_blank"><i className="fab fa-linkedin"/></a>
            </div>
          </Container>
        </nav>
        <main>
          {children}
        </main>
        <footer>
          <a href="/" className="logo-background"/>
          <Container>
            <Columns>
              <Column>
                <p>
                  Tocabi Software B.V.
                </p>
                <p>
                  KVK 78396026
                </p>
                <p>
                  <a href="mailto:thomas@tocabi.software">thomas@tocabi.software</a>
                </p>
              </Column>
              <Column>
                <Link to="/#projects">My Work</Link>
              </Column>
              <Column>
                {/* TODO */}
              </Column>
              <Column>
                <div>
                  <a href="https://github.com/ChappIO" target="_blank"><i className="fab fa-github"/></a>
                  <a href="https://www.linkedin.com/in/thomasbiesaart/" target="_blank"><i
                    className="fab fa-linkedin"/></a>
                </div>
              </Column>
            </Columns>
          </Container>
        </footer>
      </div>
    </div>
  )
}
