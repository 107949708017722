import React from "react"

export interface Props {
  width?: "tight";
}

export const Container: React.FC<Props> = ({ children, width }) => (
  <div className={`${width || ''} container`}>
    {children}
  </div>
)
